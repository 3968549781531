import { logOut } from '@/auth/services'
import useAppStore from '@/common/stores/useAppStore'
import * as Sentry from '@sentry/react-native'

export const signOut = async () => {
  try {
    await logOut()
  } catch (error) {
    Sentry.captureException(error)
  } finally {
    useAppStore.getState().logOut()
  }
}
