import type { StowlogIcon } from '@/common/models'
import { Switch } from '@rneui/themed'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { SquareIconButton } from './SquareIconButton'
import { Text } from './Text'

interface Props {
  onPress: () => void
  iconName: StowlogIcon
  text: string
  value: boolean
  onValueChange: (value: boolean) => void
}

export const SettingsItem = ({
  onPress,
  iconName,
  text,
  value,
  onValueChange
}: Props) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <SquareIconButton iconName={iconName} />

      <Text variant="mediumBold" numberOfLines={2} style={styles.text}>
        {text}
      </Text>

      <Switch value={value} onValueChange={onValueChange} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 21,
    gap: 20
  },
  text: {
    flex: 1,
    marginEnd: 'auto'
  }
})
