import { client } from '@/common/config'

export const getPermitComments = async (requestUUID: string) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/comments/{requestUUID}',
    {
      params: {
        path: {
          requestUUID
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting permit comments:', error)
    return await Promise.reject(error)
  }

  return data.data
}
