// External
import {
  Dimensions,
  Platform,
  StyleSheet,
  View,
  type ViewStyle
} from 'react-native'
// Components
import { CircleIconButton } from './CircleIconButton'
import { LanguageDropdown } from './LanguageDropdown'
import { SquareIconButton } from './SquareIconButton'
import { Text } from './Text'
// Constants
import { type colors } from '@/common/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Models
import type { StowlogIcon } from '@/common/models'

interface TopBarIcon {
  name: StowlogIcon
  onPress: () => void | Promise<void>
  showBadge?: boolean
  badgeText?: string
  color?: keyof typeof colors
  disabled?: boolean
}

interface Props {
  style?: ViewStyle
  leftIcon: TopBarIcon
  title?: string
  rightIcons?: TopBarIcon[]
  renderLanguageDropdown?: boolean
}

export const TopBar = ({
  style,
  leftIcon,
  title,
  rightIcons,
  renderLanguageDropdown = false
}: Props) => {
  const isRTL = useIsRTL()

  return (
    <View style={style}>
      <View style={styles.topBar}>
        {!(
          Platform.OS === 'web' &&
          Dimensions.get('window').width > 1024 &&
          leftIcon.name === 'menu'
        ) && (
          <CircleIconButton
            style={{
              ...styles.left,
              start: 0
            }}
            iconName={
              isRTL && leftIcon.name === 'back' ? 'next' : leftIcon.name
            }
            onPress={leftIcon.onPress}
          />
        )}

        {title !== undefined && (
          <Text variant="baseBold" style={{ textAlign: 'center' }}>
            {title}
          </Text>
        )}

        <View
          style={{
            ...styles.right,
            end: 0
          }}
        >
          {rightIcons?.map((icon, index) => (
            <SquareIconButton
              key={index}
              iconName={icon.name}
              iconSize={22}
              iconColor={icon.color}
              onPress={icon.onPress}
              showBadge={icon.showBadge}
              badgeText={icon.badgeText}
              disabled={icon.disabled}
            />
          ))}

          {renderLanguageDropdown && <LanguageDropdown />}
        </View>
      </View>
    </View>
  )
}

export const styles = StyleSheet.create({
  topBar: {
    marginVertical: 11,
    height: 52,
    justifyContent: 'center'
  },
  left: {
    position: 'absolute',
    zIndex: 1
  },
  right: {
    position: 'absolute',
    zIndex: 1,
    flexDirection: 'row',
    gap: 13
  }
})
