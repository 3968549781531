import type { GeofencesState } from '@/geoposition/models'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

const useGeofencesStore = create<GeofencesState>()(
  persist(
    (set) => ({
      geofences: undefined,
      setGeofences: (geofences) => {
        set({ geofences })
      }
    }),
    {
      name: 'geofences-storage',
      storage: createJSONStorage(() => AsyncStorage)
    }
  )
)

export default useGeofencesStore
