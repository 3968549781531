import { getGeofences } from '@/geoposition/services'
import useGeofencesStore from '@/geoposition/stores/useGeofencesStore'
import { useEffect } from 'react'

const useGeofences = () => {
  const { setGeofences } = useGeofencesStore((state) => ({
    setGeofences: state.setGeofences
  }))

  useEffect(() => {
    const fetchGeofences = async () => {
      const data = await getGeofences()
      if (data !== undefined) {
        setGeofences(data)
      }
    }

    void fetchGeofences()
  }, [])
}

export default useGeofences
