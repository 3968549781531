import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync
} from 'expo-location'
import { getPermissionsAsync } from 'expo-notifications'
import * as Sensors from 'expo-sensors'
import BackgroundGeolocation from 'react-native-background-geolocation'

export const getSettings = async () => {
  try {
    const { granted: fgGranted } = await getForegroundPermissionsAsync()
    const { granted: bgGranted } = await getBackgroundPermissionsAsync()
    const { granted: notificationsGranted } = await getPermissionsAsync()
    const { granted: motionGranted } =
      await Sensors.DeviceMotion.getPermissionsAsync()
    const { enabled, trackingMode } = await BackgroundGeolocation.getState()

    return {
      fgGranted,
      bgGranted,
      notificationsGranted,
      motionGranted,
      enabled,
      trackingMode
    }
  } catch (error) {
    console.error('Error getting settings:', error)
    return await Promise.reject(error)
  }
}
