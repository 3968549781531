import {
  getPermit,
  getPermitComments,
  getPermitSignatures
} from '@/permitToWork/services'

export const getPermitDetail = async (uuid: string) => {
  try {
    const [detail, signatures, comments] = await Promise.all([
      getPermit(uuid),
      getPermitSignatures(uuid),
      getPermitComments(uuid)
    ])

    return {
      detail,
      signatures,
      comments
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
