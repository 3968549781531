// External
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
// Constants
import { formatDateLocaleMap } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const formatDate = (
  date: Date,
  formatString: string,
  timezone?: string
) => {
  const { locale } = useAppStore.getState()

  const formatOptions = {
    ...(locale !== undefined && {
      locale: formatDateLocaleMap[locale]
    })
  }

  if (timezone !== undefined) {
    return formatInTimeZone(
      new Date(date),
      timezone,
      formatString,
      formatOptions
    )
  }

  return format(new Date(date), formatString, formatOptions)
}
