// External
// Config
import { client } from '@/common/config'
// Models
import { type SignInParams, TokenType } from '@/auth/models'
import { CustomHeaders } from '@/common/models'
// Utils
import { storeToken } from '@/auth/utils'

export const logIn = async ({ email, password }: SignInParams) => {
  try {
    const { data, error } = await client.POST('/api/auth/app/sign-in', {
      body: {
        email,
        password
      },
      headers: {
        [CustomHeaders.API_VERSION]: 2
      }
    })

    if (error !== undefined) {
      console.error('Error logging in:', error)
      return await Promise.reject(error)
    }

    await storeToken(
      data.data.accessToken,
      TokenType.AUTH,
      data.data.expirationToken
    )
    await storeToken(
      data.data.refreshToken,
      TokenType.REFRESH,
      data.data.refreshExpirationToken
    )

    return data.data.user
  } catch (error) {
    console.error('Error logging in:', error)
    return await Promise.reject(error)
  }
}
