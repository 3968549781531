// External
import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync
} from 'expo-location'
import { getPermissionsAsync } from 'expo-notifications'
import { useCallback, useEffect } from 'react'
import { AppState, Platform } from 'react-native'
// Components
import { MissingPermissions } from '@/permissions/components'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'

const useCheckPermissions = () => {
  if (Platform.OS === 'web') {
    return
  }

  const { askForPermissions } = useAppStore((state) => ({
    askForPermissions: state.askForPermissions
  }))
  const { modalVisible, showModal } = usePortalStore((state) => ({
    modalVisible: state.modalVisible,
    showModal: state.showModal
  }))

  const checkPermissions = useCallback(async () => {
    try {
      const fgPermissions = await getForegroundPermissionsAsync()
      const bgPermissions = await getBackgroundPermissionsAsync()
      const permissions = await getPermissionsAsync()

      const permissionsGranted =
        fgPermissions.granted && bgPermissions.granted && permissions.granted

      if (!askForPermissions && !modalVisible && !permissionsGranted) {
        showModal({
          content: (
            <MissingPermissions
              backgroundLocation={bgPermissions.granted}
              foregroundLocation={fgPermissions.granted}
              notifications={permissions.granted}
            />
          )
        })
      }
    } catch (error) {
      console.error(error)
    }
  }, [askForPermissions, modalVisible])

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        void checkPermissions()
      }
    })

    return () => {
      subscription.remove()
    }
  }, [checkPermissions])
}

export default useCheckPermissions
