// External
import type { DrawerScreenProps } from '@react-navigation/drawer'
import * as Linking from 'expo-linking'
import {
  getBackgroundPermissionsAsync,
  getCurrentPositionAsync,
  getForegroundPermissionsAsync,
  requestBackgroundPermissionsAsync,
  requestForegroundPermissionsAsync
} from 'expo-location'
import { isPointWithinRadius } from 'geolib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Platform } from 'react-native'
import BackgroundGeolocation from 'react-native-background-geolocation'
import MapView, { Overlay, PROVIDER_GOOGLE } from 'react-native-maps'
// Components
import { TopBar } from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { components } from '@/common/models'
import type { HomeDrawerParamList } from '@/home/models'
// Stores
import useGeofencesStore from '@/geoposition/stores/useGeofencesStore'
import useGeopositionConfigStore from '@/geoposition/stores/useGeopositionConfigStore'

type Props = DrawerScreenProps<HomeDrawerParamList, 'Geoposition'>

const Geoposition = ({ navigation }: Props) => {
  const geopositionConfig = useGeopositionConfigStore(
    (state) => state.geopositionConfig
  ) as components['schemas']['GeopositionConfiguration']
  const { geofences } = useGeofencesStore((state) => ({
    geofences: state.geofences
  }))
  const [starting, setStarting] = useState(false)
  const { t } = useTranslation()

  const { overlay } = geopositionConfig
  const bounds = Platform.select({
    android: [
      [overlay.bounds.rightBottomLatitude, overlay.bounds.leftTopLongitude],
      [overlay.bounds.leftTopLatitude, overlay.bounds.rightBottomLongitude]
    ],
    ios: [
      [overlay.bounds.leftTopLatitude, overlay.bounds.leftTopLongitude],
      [overlay.bounds.rightBottomLatitude, overlay.bounds.rightBottomLongitude]
    ]
  })

  return (
    <SafeArea edges={['top', 'left', 'right']}>
      <TopBar
        style={{
          paddingHorizontal: 25,
          paddingBottom: 14
        }}
        title={t('geoposition')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack,
          disabled: starting
        }}
        rightIcons={[
          {
            name: 'power',
            onPress: async () => {
              try {
                setStarting(true)

                const { granted: fgGranted, canAskAgain: fgCanAskAgain } =
                  await getForegroundPermissionsAsync()
                const { granted: bgGranted, canAskAgain: bgCanAskAgain } =
                  await getBackgroundPermissionsAsync()

                if (
                  (!fgGranted && !fgCanAskAgain) ||
                  (!bgGranted && !bgCanAskAgain)
                ) {
                  Alert.alert(
                    t('alwaysPermissionRequiredTitle'),
                    t('alwaysPermissionRequiredMessage'),
                    [
                      {
                        text: t('openSettings'),
                        onPress: () => {
                          void Linking.openSettings()
                        }
                      },
                      {
                        text: t('cancel'),
                        style: 'cancel'
                      }
                    ],
                    {
                      cancelable: true
                    }
                  )
                  return
                }

                let fgResponseGranted = false
                let bgResponseGranted = false
                if (!fgGranted && fgCanAskAgain) {
                  const fgResponse = await requestForegroundPermissionsAsync()
                  fgResponseGranted = fgResponse.granted
                }
                if (!bgGranted && bgCanAskAgain) {
                  const bgResponse = await requestBackgroundPermissionsAsync()
                  bgResponseGranted = bgResponse.granted
                }

                if (
                  ((fgGranted && bgGranted) ||
                    (fgResponseGranted && bgResponseGranted)) &&
                  geofences !== undefined &&
                  geofences.length > 0
                ) {
                  const { coords } = await getCurrentPositionAsync()
                  for (const geofence of geofences) {
                    if (
                      isPointWithinRadius(
                        coords,
                        {
                          latitude: geofence.latitude,
                          longitude: geofence.longitude
                        },
                        geofence.radius
                      )
                    ) {
                      await BackgroundGeolocation.start()
                      toast.showSuccess({
                        data: {
                          messageTranslationKey: t('trackingStarted')
                        }
                      })
                      return
                    }
                  }

                  toast.showError({
                    data: {
                      messageTranslationKey: t('trackingCouldntBeStarted')
                    }
                  })
                }
              } catch (error: any) {
                console.error(error)
                toast.showError({
                  data: {
                    messageTranslationKey: String(error.message)
                  }
                })
              } finally {
                setStarting(false)
              }
            }
          }
        ]}
      />

      <MapView
        style={{ flex: 1 }}
        provider={Platform.OS === 'web' ? 'google' : PROVIDER_GOOGLE}
        showsCompass
        showsMyLocationButton
        showsUserLocation
        followsUserLocation
        showsBuildings={false}
        showsPointsOfInterest={false}
        showsIndoors={false}
        loadingEnabled
        customMapStyle={require('@assets/json/geopositionMapStyle.json')}
        initialCamera={overlay.camera}
      >
        {Platform.OS !== 'web' && (
          <Overlay
            bearing={overlay.bearing}
            // @ts-expect-error - it works sooooooo
            bounds={bounds}
            image={{ uri: overlay.overlayUrl }}
          />
        )}
      </MapView>
    </SafeArea>
  )
}
export default Geoposition
