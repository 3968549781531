// External
import { type Locale } from 'date-fns'
import {
  ar,
  ca,
  de,
  enGB,
  enUS,
  es,
  fr,
  hr,
  hu,
  it,
  pl,
  ro,
  sv
} from 'date-fns/locale'
// Models
import { type StowlogLocale } from '@/common/models'

export const formatDateLocaleMap: Record<StowlogLocale, Locale> = {
  ar,
  ca,
  de,
  'en-GB': enGB,
  'en-US': enUS,
  es,
  fr,
  hr,
  hu,
  it,
  pl,
  sv,
  ro
}
