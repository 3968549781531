import { Button, Text } from '@/common/components'
import { SafeArea } from '@/common/layouts'
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  resetError: () => void
}

const ErrorView = ({ resetError }: Props) => {
  return (
    <SafeArea
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 25
      }}
      withBackground
    >
      <Text variant="baseBold">An unknown error has occurred</Text>
      <Text>Reload Stowlog using the button below</Text>

      <Button
        style={{ marginTop: 34 }}
        title="Reload Stowlog"
        onPress={resetError}
      />

      <Button
        type="secondary"
        style={{ marginTop: 20 }}
        title="Log out"
        onPress={() => {
          useAppStore.getState().logOut()
          resetError()
        }}
      />
    </SafeArea>
  )
}
export default ErrorView
