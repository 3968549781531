// Services
import { getUser } from '@/common/services'
import { selectFacilityUserType } from '@/findFacility/services'
import { getMissingCustomFields } from '@/profile/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'

export const logIntoFacility = async (facilityUserTypeId: string) => {
  try {
    await selectFacilityUserType(facilityUserTypeId)
    const user = await getUser()

    const missingCustomFields = await getMissingCustomFields()
    useAppStore.getState().setMissingCustomFields(missingCustomFields)

    return { user, missingCustomFields }
  } catch (error) {
    return await Promise.reject(error)
  }
}
