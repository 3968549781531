import { Text } from '@/common/components'
import type { components } from '@/common/models'
import type { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'

interface Props {
  comments: Array<components['schemas']['PTWCommentDto']>
}

export const PermitComments = ({ comments }: Props) => {
  const { t } = useTranslation()
  let children: ReactNode

  if (comments.length === 1) {
    children = <Text variant="small">{comments[0]?.content}</Text>
  } else {
    children = (
      <>
        {comments.map((comment) => (
          <Text variant="small" key={comment.uuid}>
            {`• ${comment.content}`}
          </Text>
        ))}
      </>
    )
  }

  return (
    <View>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('comments')}
      </Text>

      {children}
    </View>
  )
}
