// External
import {
  type DrawerContentComponentProps,
  DrawerContentScrollView
} from '@react-navigation/drawer'
import * as Linking from 'expo-linking'
import { useTranslation } from 'react-i18next'
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
// Components
import { Avatar } from './Avatar'
import { DrawerContentItem } from './DrawerContentItem'
import { Icon } from './Icon'
import { Text } from './Text'
// Constants
import { colors, toast } from '@/common/constants'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Models
// Services
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { signOut } from '@/auth/useCases/signOut'
// Utils
import { handleError } from '@/common/utils'

export const DrawerContent = (props: DrawerContentComponentProps) => {
  const { user, currentUserType, currentFacility, logOutOfFacility } =
    useAppStore((state) => ({
      user: state.user,
      currentUserType: state.currentUserType,
      currentFacility: state.currentFacility,
      logOutOfFacility: state.logOutOfFacility
    }))
  const { t } = useTranslation()
  const isRTL = useIsRTL()

  if (user === undefined) {
    // ! When logging out this component is still mounted for a brief moment, which causes the app to crash as user
    // ! becomes undefined. This is a temporary fix until we find a better solution.
    // TODO - Find a better solution
    return null
  }

  return (
    <DrawerContentScrollView style={{ marginTop: 11 }} bounces={false}>
      {currentFacility !== undefined && (
        <>
          <View style={styles.facility}>
            <Avatar
              size={56}
              source={{ uri: currentFacility.onboarding?.avatarUrl }}
            />

            <Text style={styles.facilityName} variant="baseBold">
              {currentFacility.name}
            </Text>

            {!(
              Platform.OS === 'web' && Dimensions.get('window').width > 1024
            ) && (
              <Icon
                name={Platform.OS === 'web' || isRTL ? 'back' : 'next'}
                size={20}
                onPress={props.navigation.closeDrawer}
              />
            )}
          </View>

          <LinearGradient
            style={styles.gradientDivider}
            colors={['#2A3F44', '#2A3F4400']}
            useAngle
            angle={isRTL ? -90 : 90}
          />
        </>
      )}

      <View style={{ marginHorizontal: 25 }}>
        <TouchableOpacity
          style={styles.user}
          {...(currentFacility !== undefined && {
            onPress: () => {
              props.navigation.navigate('Profile')
            }
          })}
        >
          <Avatar
            style={{ marginEnd: 16 }}
            size={56}
            {...(user.profileImage !== undefined && {
              source: { uri: user.profileImage }
            })}
            initials={user.firstName.charAt(0) + user.lastName.charAt(0)}
          />

          <View style={{ flex: 1 }}>
            <Text variant="baseBold">{user.fullName}</Text>

            {currentUserType !== undefined && (
              <Text numberOfLines={1} variant="smallBold" color={colors.hydro}>
                {t(`userTypes.${currentUserType.slug}`)}
              </Text>
            )}

            {currentFacility === undefined && (
              <Text numberOfLines={1} style={{ opacity: 0.5 }}>
                {t('noSelectedFacility')}
              </Text>
            )}
          </View>
        </TouchableOpacity>

        {currentFacility !== undefined && (
          <>
            <DrawerContentItem
              style={styles.item}
              onPress={() => {
                props.navigation.navigate('Profile')
              }}
              iconName="user-name"
              text={t('profile')}
            />

            <DrawerContentItem
              style={styles.item}
              onPress={logOutOfFacility}
              iconName="change-facility"
              text={t('changeFacility')}
            />
          </>
        )}

        <DrawerContentItem
          style={styles.item}
          onPress={() => {
            props.navigation.navigate('Settings')
          }}
          iconName="settings"
          text={t('settings')}
        />

        <DrawerContentItem
          style={styles.item}
          onPress={() => {
            if (Platform.OS === 'web') {
              window.open(
                'https://estudiocactus.atlassian.net/servicedesk/customer/portal/15',
                '_blank'
              )
              return
            }

            void Linking.openURL(
              'https://estudiocactus.atlassian.net/servicedesk/customer/portal/15'
            )
          }}
          iconName="help"
          text={t('help')}
        />

        <DrawerContentItem
          style={styles.item}
          color={colors.hydro}
          onPress={async () => {
            try {
              await signOut()
              toast.showSuccess({
                data: { messageTranslationKey: 'successfulSignout' }
              })
            } catch (error) {
              handleError(error)
            }
          }}
          iconName="logout"
          text={t('logOut')}
        />
      </View>
    </DrawerContentScrollView>
  )
}

const styles = StyleSheet.create({
  facility: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
    gap: 16,
    marginHorizontal: 25
  },
  facilityName: {
    flex: 1,
    marginEnd: 'auto'
  },
  gradientDivider: {
    height: 2,
    width: '100%',
    marginBottom: 20,
    opacity: 0.1
  },
  user: {
    flexDirection: 'row',
    marginBottom: 40,
    alignItems: 'center'
  },
  item: {
    flexDirection: 'row',
    gap: 10,
    marginBottom: 32
  }
})
