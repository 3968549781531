// External
import type { PTWStackParamList } from '@/permitToWork/models'
import type { StackScreenProps } from '@react-navigation/stack'
import { Divider } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { FacilityTimezone, Input, Text, TopBar } from '@/common/components'
import {
  PermitArea,
  PermitArrangementList,
  PermitAttachmentList,
  PermitComments,
  PermitDetailSkeleton,
  PermitLocationList,
  PermitPersonInCharge,
  PermitPPEList,
  PermitRejectionCard,
  PermitRequester,
  PermitSignatures,
  PermitStaffOnSiteList,
  PermitStatus,
  PermitTypeOfWorks
} from '@/permitToWork/components'
// Layouts
import { SafeArea } from '@/common/layouts'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { callSOS, formatDate } from '@/common/utils'
import { getExtendedPermitStatus } from '@/permitToWork/utils'
// Use cases
import { getPermitDetail } from '@/permitToWork/useCases'
import usePTWConfigStore from '../stores/usePTWConfigStore'

type Props = StackScreenProps<PTWStackParamList, 'PermitDetail'>

const PermitDetail = ({ navigation, route }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { ptwConfig } = usePTWConfigStore((state) => ({
    ptwConfig: state.ptwConfig
  }))
  const { t } = useTranslation()
  const { top, right, left, bottom } = useSafeAreaInsets()
  const { height } = useWindowDimensions()

  const { uuid, seq } = route.params

  const { data, refetch, isRefetching, isLoading } = useQuery({
    queryKey: ['permitDetail', uuid],
    queryFn: async () => await getPermitDetail(uuid)
  })

  if (data === undefined || isLoading) {
    return (
      <SafeArea>
        <TopBar
          style={styles.topBar}
          title={t('requestID', { id: seq })}
          leftIcon={{
            name: 'close',
            onPress: navigation.goBack
          }}
        />

        <PermitDetailSkeleton />
      </SafeArea>
    )
  }

  const { detail, signatures, comments } = data
  const status = getExtendedPermitStatus(detail)

  return (
    <View
      style={{
        flex: 1,
        paddingTop: top,
        paddingRight: right,
        paddingLeft: left
      }}
    >
      <TopBar
        style={styles.topBar}
        title={t('requestID', { id: seq })}
        leftIcon={{
          name: 'close',
          onPress: navigation.goBack
        }}
        rightIcons={[
          {
            name: 'sos',
            onPress: () => {
              // eslint-disable-next-line
              void callSOS(currentFacility!)
            },
            color: 'error'
          }
        ]}
      />

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 25,
          paddingBottom: bottom + height * 0.25
        }}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isRefetching}
            progressViewOffset={-15}
          />
        }
      >
        <PermitPersonInCharge
          personInChargeContractor={detail.personInChargeContractor}
          personInChargePhoneNumber={detail.personInChargePhoneNumber}
        />

        <Divider style={{ marginVertical: 20 }} />

        <PermitRequester requesterContractor={detail.requesterContractor} />

        <View style={styles.dateAndStatus}>
          <View style={styles.createdAt}>
            <Text variant="small">
              {t('requestCreatedAt', {
                date: formatDate(
                  new Date(detail.createdAt),
                  'Pp',
                  currentFacility?.info.timezone
                )
              })}
            </Text>
          </View>

          <PermitStatus compact status={status} />
        </View>

        <FacilityTimezone style={{ marginTop: 12 }} />

        {detail.rejectionReason !== undefined && (
          <PermitRejectionCard
            style={{ marginTop: 24 }}
            compact
            rejectionReason={detail.rejectionReason}
          />
        )}

        <Divider style={{ marginVertical: 20 }} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 12
          }}
        >
          <Input
            style={{ flex: 1 }}
            disabled
            label={t('startDate')}
            value={formatDate(
              new Date(detail.startDate),
              'Pp',
              currentFacility?.info.timezone
            )}
          />

          <Input
            style={{ flex: 1 }}
            disabled
            label={t('endDate')}
            value={formatDate(
              new Date(detail.endDate),
              'Pp',
              currentFacility?.info.timezone
            )}
          />
        </View>

        <Input disabled label={t('description')} value={detail.description} />

        <PermitStaffOnSiteList
          permitUUID={detail.uuid}
          status={detail.status}
          staffOnSite={detail.staffOnSite}
          refetch={refetch}
        />

        {(ptwConfig?.hasAreaSelectionEnabled ?? false) && (
          <PermitArea area={detail.area} />
        )}

        <PermitLocationList
          locations={detail.locations}
          otherLocation={detail.otherLocation}
        />

        {(ptwConfig?.hasPreArrangementsEnabled ?? false) && (
          <PermitArrangementList
            arrangements={detail.preArrangementQuestions}
          />
        )}

        <PermitPPEList ppes={detail.ppeRequirements} />

        <PermitTypeOfWorks
          genericTypeOfWorks={Boolean(detail.genericTypeOfWork)}
          typeOfworks={detail.typeOfWorks}
        />

        {detail.attachments != null && detail.attachments.length > 0 && (
          <PermitAttachmentList attachments={detail.attachments} />
        )}

        {comments.length > 0 && <PermitComments comments={comments} />}
      </ScrollView>

      <PermitSignatures signatures={signatures} />
    </View>
  )
}

export default PermitDetail

const styles = StyleSheet.create({
  topBar: {
    marginBottom: 27,
    marginHorizontal: 25
  },
  createdAt: {
    paddingHorizontal: 10,
    paddingVertical: 6.5,
    backgroundColor: '#FAFAFA',
    borderRadius: 7,
    marginEnd: 'auto'
  },
  dateAndStatus: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8
  }
})
