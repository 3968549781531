import { client } from '@/common/config'

export const getGeofences = async () => {
  const { data, error } = await client.GET(
    '/api/public/geoposition/configuration/geofences'
  )

  if (error !== undefined) {
    console.error('Error getting geofences:', error)
    return await Promise.reject(error)
  }

  return data.data
}
