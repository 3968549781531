// External
import { DefaultTheme, NavigationContainer } from '@react-navigation/native'
import { Overlay, ThemeProvider } from '@rneui/themed'
import * as Sentry from '@sentry/react-native'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Constants from 'expo-constants'
import * as Linking from 'expo-linking'
import { getLastNotificationResponseAsync } from 'expo-notifications'
import * as SplashScreen from 'expo-splash-screen'
import { StatusBar } from 'expo-status-bar'
import React from 'react'
import { Platform, View, useWindowDimensions } from 'react-native'
import { SheetProvider } from 'react-native-actions-sheet'
import { KeyboardProvider } from 'react-native-keyboard-controller'
import { Provider as PaperProvider } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import Toast from 'react-native-toast-notifications'
// Components
import { CustomToast, ModeIndicator } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Config
import { theme } from '@/common/config'
import '@/common/config/i18n'
import '@/common/config/sheets'
// Hooks
import useAssets from '@/common/hooks/useAssets'
import useCheckAppVersion from '@/common/hooks/useCheckAppVersion'
import useEASUpdate from '@/common/hooks/useEASUpdate'
import useIsRTL from '@/common/hooks/useIsRTL'
import useReactQueryFocusManager from '@/common/hooks/useReactQueryFocusManager'
import useUpdateUserSettings from '@/common/hooks/useUpdateUserSettings'
import useGeofences from '@/geoposition/hooks/useGeofences'
import useGeoposition from '@/geoposition/hooks/useGeoposition'
import useNotifications from '@/notifications/hooks/useNotifications'
import useCheckPermissions from '@/permissions/hooks/useCheckPermissions'
// Navigators
import CustomFieldsStack from '@/common/navigators/CustomFieldsStack'
import PreHomeStack from '@/common/navigators/PreHomeStack'
import HomeDrawer from '@/home/navigators/HomeDrawer'
// Services
import { getUser } from '@/common/services'
import {
  getFacilityDetail,
  getUserType,
  selectFacility,
  selectFacilityUserType
} from '@/findFacility/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { onPressNotificationAction } from '@/notifications/utils'
// Wrappers
import ErrorBoundary from '@/common/wrappers/ErrorBoundary'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

void SplashScreen.preventAutoHideAsync()

// if (!__DEV__) {
Sentry.init({
  environment:
    Constants.expoConfig?.extra?.mode === 'PRODUCTION'
      ? 'production'
      : 'development',
  dsn: 'https://53caf4545a6354e14c9471cf42f9be35@o4505713690935296.ingest.sentry.io/4505713848221696',
  tracesSampleRate: 1.0
})
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3
    }
  }
})

const App = () => {
  useReactQueryFocusManager()
  useNotifications()

  const assetsLoaded = useAssets()

  const {
    colorScheme,
    user,
    currentUserType,
    isLoggedIn,
    missingCustomFields,
    currentFacility,
    logIntoFacility
  } = useAppStore((state) => ({
    colorScheme: state.colorScheme,
    user: state.user,
    currentUserType: state.currentUserType,
    isLoggedIn: state.isLoggedIn,
    missingCustomFields: state.missingCustomFields,
    currentFacility: state.currentFacility,
    logIntoFacility: state.logIntoFacility
  }))
  const { modalVisible, modalChildren, modalDismissable, hideModal } =
    usePortalStore((state) => ({
      modalVisible: state.modalVisible,
      modalChildren: state.modalChildren,
      modalDismissable: state.modalDismissable,
      hideModal: state.hideModal
    }))

  useCheckAppVersion()
  useEASUpdate()
  useCheckPermissions()
  useUpdateUserSettings(isLoggedIn)
  useGeofences()
  useGeoposition()
  const isRTL = useIsRTL()
  const dimensions = useWindowDimensions()

  if (!assetsLoaded) {
    return null
  }

  const renderNavigator = () => {
    if (
      user !== undefined &&
      currentUserType !== undefined &&
      currentFacility !== undefined &&
      missingCustomFields.length === 0
    ) {
      return <HomeDrawer />
    }

    if (currentFacility !== undefined || missingCustomFields.length > 0) {
      return <CustomFieldsStack />
    }

    return <PreHomeStack />
  }

  return (
    <SafeAreaProvider
      {...(Platform.OS === 'web' &&
        dimensions.width > 1280 && {
          style: {
            backgroundColor: colors.drawer
          }
        })}
    >
      <View
        {...(Platform.OS === 'web' && { dir: isRTL ? 'rtl' : 'ltr' })}
        style={{
          flex: 1,
          // ! Prevents the patched RTL drawer on web from causing overflow
          ...(Platform.OS === 'web' && {
            overflowX: 'hidden',
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: colors.lightGray10Percent,
            ...(dimensions.width > 1280 && { marginHorizontal: '10%' })
          })
        }}
      >
        <StatusBar style={colorScheme === 'light' ? 'dark' : 'light'} />

        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <KeyboardProvider>
                <Overlay
                  isVisible={modalVisible}
                  onBackdropPress={modalDismissable ? hideModal : undefined}
                >
                  {modalChildren}
                </Overlay>

                <GestureHandlerRootView style={{ flex: 1 }}>
                  <SheetProvider>
                    <NavigationContainer
                      theme={{
                        ...DefaultTheme,
                        colors: {
                          ...DefaultTheme.colors,
                          background: colors.white
                        }
                      }}
                      linking={{
                        prefixes: [
                          Linking.createURL('/'),
                          'https://app.stowlog.com',
                          'https://app-dev.stowlog.com'
                        ],
                        config: {
                          screens: {
                            Signin: 'signin',
                            RestorePassword: 'restore-password',
                            Notifications: 'notifications'
                          }
                        },
                        getInitialURL: async () => {
                          try {
                            const initialURL = await Linking.getInitialURL()

                            const lastNotification =
                              await getLastNotificationResponseAsync()

                            if (lastNotification !== null) {
                              const lastNotificationData =
                                lastNotification.notification.request.content
                                  .data
                              const { facilityId, userTypeId } =
                                lastNotificationData

                              if (
                                currentUserType !== undefined &&
                                currentFacility !== undefined
                              ) {
                                if (
                                  (facilityId !== currentFacility._id &&
                                    userTypeId !== currentUserType._id) ||
                                  facilityId !== currentFacility._id
                                ) {
                                  const userType = await getUserType(userTypeId)
                                  const facility = await getFacilityDetail(
                                    facilityId
                                  )

                                  await selectFacility(facilityId)

                                  await selectFacilityUserType(userTypeId)

                                  const user = await getUser()

                                  logIntoFacility(user, userType, [], facility)
                                } else if (userTypeId !== currentUserType._id) {
                                  const userType = await getUserType(userTypeId)

                                  await selectFacilityUserType(userTypeId)

                                  const user = await getUser()

                                  logIntoFacility(
                                    user,
                                    userType,
                                    [],
                                    currentFacility
                                  )
                                }
                              }

                              void onPressNotificationAction({
                                actionData: lastNotificationData.actionData,
                                actionType: lastNotificationData.actionType
                              })
                              const url = `${Linking.createURL(
                                '/'
                              )}notifications`
                              return url
                            }

                            return initialURL
                          } catch (error) {
                            console.error('Error getting initial URL:', error)
                            return null
                          }
                        }
                      }}
                    >
                      <PaperProvider>{renderNavigator()}</PaperProvider>
                    </NavigationContainer>

                    <Toast
                      ref={(ref) => ((global as any).toastNotifications = ref)}
                      placement="top"
                      renderToast={CustomToast}
                    />
                  </SheetProvider>
                </GestureHandlerRootView>
                <ModeIndicator />
              </KeyboardProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </View>
    </SafeAreaProvider>
  )
}

export default Sentry.wrap(App)
