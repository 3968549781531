// External
import * as SecureStore from 'expo-secure-store'
import { Platform } from 'react-native'
// Models
import { type TokenType } from '@/auth/models'

export const storeToken = async (
  token: string,
  type: TokenType,
  expirationDate: string
) => {
  try {
    if (Platform.OS === 'web') {
      const expires = new Date(expirationDate)
      const cookieOptions = `expires=${expires.toUTCString()};${
        !__DEV__ ? 'httpsOnly; secure: sameSite=strict;' : ''
      }`
      document.cookie = `${type}=${token}; ${cookieOptions}`
      return
    }

    await SecureStore.setItemAsync(type, token, {
      keychainAccessible: SecureStore.AFTER_FIRST_UNLOCK
    })
    await SecureStore.setItemAsync(`${type}_expiration`, expirationDate, {
      keychainAccessible: SecureStore.AFTER_FIRST_UNLOCK
    })
  } catch (error) {
    console.error('Error storing token:', error)
    return await Promise.reject(error)
  }
}
