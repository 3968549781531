// External
// import { Appearance } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Sentry from '@sentry/react-native'
import { getForegroundPermissionsAsync } from 'expo-location'
import { setBadgeCountAsync } from 'expo-notifications'
import BackgroundGeolocation from 'react-native-background-geolocation'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
// Models
import { type AppState } from '@/common/models'
// Utils
import { removeTokens } from '@/auth/utils'

const updatePositionOnAuthChange = async () => {
  try {
    const { granted } = await getForegroundPermissionsAsync()
    if (granted) {
      void BackgroundGeolocation.getCurrentPosition({
        samples: 1,
        persist: true
      })
    }
  } catch (error) {
    console.error('Error updating position on log out:', error)
    Sentry.captureException(error)
  }
}

const useAppStore = create<AppState>()(
  persist(
    (set, get) => ({
      locale: undefined,
      setLocale: (locale) => {
        set({ locale })
      },
      // colorScheme: Appearance.getColorScheme() ?? 'light',
      colorScheme: 'light',
      setColorScheme: (colorScheme) => {
        set({ colorScheme })
      },
      user: undefined,
      setUser: (user) => {
        set({ user })
      },
      currentUserType: undefined,
      isLoggedIn: false,
      missingCustomFields: [],
      setMissingCustomFields: (missingCustomFields) => {
        set({ missingCustomFields })
      },
      currentFacility: undefined,
      setCurrentFacility: (facility) => {
        set({ currentFacility: facility })
      },
      currentModules: [],
      setCurrentModules: (currentModules) => {
        set({ currentModules })
      },
      logIn: (user) => {
        set({ user, isLoggedIn: true })
        void updatePositionOnAuthChange()
      },
      logIntoFacility: (user, userType, missingCustomFields, facility) => {
        set({
          user,
          currentUserType: userType,
          missingCustomFields,
          currentFacility: facility
        })
        void updatePositionOnAuthChange()
      },
      removeUserType: () => {
        set({
          missingCustomFields: [],
          currentUserType: undefined,
          currentModules: []
        })
      },
      logOutOfFacility: () => {
        set({
          missingCustomFields: [],
          currentFacility: undefined,
          currentUserType: undefined,
          currentModules: []
        })
        void updatePositionOnAuthChange()
        void setBadgeCountAsync(0)
      },
      logOut: () => {
        set({
          user: undefined,
          currentUserType: undefined,
          isLoggedIn: false,
          missingCustomFields: [],
          currentFacility: undefined,
          currentModules: []
        })
        void updatePositionOnAuthChange()
        void removeTokens()
        void setBadgeCountAsync(0)
      },
      askForPermissions: true,
      setAskForPermissions: (askForPermissions) => {
        set({ askForPermissions })
      }
    }),
    {
      name: 'app-storage',
      storage: createJSONStorage(() => AsyncStorage)
    }
  )
)

export default useAppStore
