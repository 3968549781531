import i18n from '@/common/config/i18n'
import { array, boolean, number, object, string } from 'yup'

const { t } = i18n

const checkinQuestionSchema = object({
  questionId: string().required(),
  optional: boolean().required(),
  answer: string().when('optional', {
    is: false,
    then: (schema) => schema.required(t('requiredField')),
    otherwise: (schema) => schema.optional()
  })
})

export const requestVisitFormSchema = object({
  date: object({
    day: number().required(),
    month: number().required(),
    year: number().required()
  }).required(t('requiredField')),
  checkIn: string().required(t('requiredField')),
  checkOut: string().required(t('requiredField')),
  hosts: array(string().required())
    .required()
    .min(1, t('selectAtLeastOneHost')),
  visitQuestionAnswers: array(checkinQuestionSchema).required()
})
