// External
import * as Sentry from '@sentry/react-native'
import {
  getBackgroundPermissionsAsync,
  getForegroundPermissionsAsync
} from 'expo-location'
import { useEffect, useState } from 'react'
import { AppState, Platform, type AppStateStatus } from 'react-native'
import BackgroundFetch from 'react-native-background-fetch'
import BackgroundGeolocation, {
  type Geofence,
  type Subscription
} from 'react-native-background-geolocation'
import { getUniqueIdSync } from 'react-native-device-info'
// Constants
import { backgroundGeolocationConfig } from '@/geoposition/constants'
// Hooks
import useExpoPushToken from '@/common/hooks/useExpoPushToken'
// Models
import type { GeopositionRestLocationExtras } from '@/geoposition/models'
// Services
import { leaveGeofence } from '@/geoposition/services/leaveGeofence'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import useGeofencesStore from '@/geoposition/stores/useGeofencesStore'

const useGeoposition = () => {
  if (Platform.OS === 'web') {
    return
  }

  const { user, currentUserType, askForPermissions } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    askForPermissions: state.askForPermissions
  }))
  const { geofences } = useGeofencesStore((state) => ({
    geofences: state.geofences
  }))
  const [ready, setReady] = useState(false)
  const [permissionsGranted, setPermissionsGranted] = useState(false)
  const expoPushToken = useExpoPushToken()

  // Gets value of permissions every time the app is foregrounded
  useEffect(() => {
    const getFirstStates = async () => {
      try {
        const fgPermissions = await getForegroundPermissionsAsync()
        const bgPermissions = await getBackgroundPermissionsAsync()

        if (fgPermissions.granted && bgPermissions.granted) {
          setPermissionsGranted(true)
        } else {
          setPermissionsGranted(false)
        }
      } catch (error) {
        console.error(
          'Error getting first value of location permission states:',
          error
        )
        Sentry.captureException(error)
      }
    }

    void getFirstStates()

    const listener = async (nextAppState: AppStateStatus) => {
      const fgPermissions = await getForegroundPermissionsAsync()
      const bgPermissions = await getBackgroundPermissionsAsync()

      if (fgPermissions.granted && bgPermissions.granted) {
        setPermissionsGranted(true)
      } else {
        setPermissionsGranted(false)
      }
    }

    const subscription = AppState.addEventListener('change', (nextAppState) => {
      void listener(nextAppState)
    })

    return () => {
      subscription.remove()
    }
    // [askForPermissions] causes the permissions to be reloaded after the user has completed the permission
    // prompts during a fresh install
  }, [askForPermissions])

  // Sets up event listeners and readies the plugin
  useEffect(() => {
    let onHeartbeat: Subscription

    const onGeofence: Subscription = BackgroundGeolocation.onGeofence(
      (geofence) => {
        const { action, identifier, extras } = geofence
        console.log(`[Geofence ${identifier} event]`)
        console.log(action, extras)

        if (action === 'ENTER' && extras?.main === true) {
          if (Platform.OS === 'android') {
            setTimeout(() => {
              BackgroundGeolocation.start()
                .then(() => {
                  BackgroundGeolocation.changePace(true).catch((error) => {
                    console.error(
                      '[ANDROID] Error changing pace after entering geofence:',
                      error
                    )
                    Sentry.captureException(error)
                  })
                })
                .catch((error) => {
                  console.error(
                    '[ANDROID] Error starting location tracking after entering geofence:',
                    error
                  )
                  Sentry.captureException(error)
                })
            }, 10 * 1000)
          } else {
            BackgroundGeolocation.start()
              .then(() => {
                BackgroundGeolocation.changePace(true).catch((error) => {
                  console.error(
                    '[iOS] Error changing pace after entering geofence:',
                    error
                  )
                  Sentry.captureException(error)
                })
              })
              .catch((error) => {
                console.error(
                  '[iOS] Error starting location tracking after entering geofence:',
                  error
                )
                Sentry.captureException(error)
              })
          }
        }

        if (action === 'EXIT' && extras?.main === true) {
          BackgroundGeolocation.startGeofences()
            .then(() => {
              leaveGeofence(getUniqueIdSync())
            })
            .catch((error) => {
              console.error(
                'Error starting geofence detection after exiting geofence:',
                error
              )
              Sentry.captureException(error)
            })
        }
      }
    )

    const onLocation: Subscription = BackgroundGeolocation.onLocation(
      (location) => {
        console.log('[Location update]')
        // console.log('extras:', location.extras)
      }
    )

    const onHttp: Subscription = BackgroundGeolocation.onHttp((event) => {
      console.log('[HTTP event]', event)
    })

    if (Platform.OS === 'android') {
      onHeartbeat = BackgroundGeolocation.onHeartbeat(() => {
        console.log('[Heartbeat event] ')

        void BackgroundGeolocation.getCurrentPosition({
          samples: 1,
          persist: true
        })
      })
    }

    if (Platform.OS === 'ios') {
      BackgroundFetch.configure(
        {
          minimumFetchInterval: 15
        },
        (taskId) => {
          void BackgroundGeolocation.getCurrentPosition({
            samples: 1,
            persist: true
          })

          BackgroundFetch.finish(taskId)
        },
        (taskId) => {
          BackgroundFetch.finish(taskId)
        }
      )
        .then(() => {
          BackgroundFetch.start().catch((error) => {
            console.error('Error starting iOS background fetch:', error)
            Sentry.captureException(error)
          })
        })
        .catch((error) => {
          console.error('Error configuring iOS background fetch:', error)
          Sentry.captureException(error)
        })
    }

    BackgroundGeolocation.ready(backgroundGeolocationConfig)
      .then(() => {
        setReady(true)
      })
      .catch((error) => {
        console.error('Error readying geoposition:', error)
        Sentry.captureException(error)
      })

    return () => {
      onGeofence?.remove()
      onLocation?.remove()
      onHttp?.remove()
      if (Platform.OS === 'android' && onHeartbeat !== undefined) {
        onHeartbeat.remove()
      }
      if (Platform.OS === 'ios') {
        BackgroundFetch.stop().catch((error) => {
          console.error('Error stopping iOS background fetch:', error)
          Sentry.captureException(error)
        })
      }
    }
  }, [])

  // Starts plugin on geofences mode once configured and permissions are granted
  useEffect(() => {
    if (ready && permissionsGranted) {
      BackgroundGeolocation.getState()
        .then(({ enabled, trackingMode }) => {
          if (enabled && trackingMode === 1) {
            BackgroundGeolocation.start()
              .then(() => {
                BackgroundGeolocation.changePace(true).catch((error) => {
                  console.error(
                    'Error changing pace after entering geofence:',
                    error
                  )
                  Sentry.captureException(error)
                })
              })
              .catch((error) => {
                console.error('Error starting location tracking:', error)
                Sentry.captureException(error)
              })
          } else {
            BackgroundGeolocation.startGeofences().catch((error) => {
              console.error('Error starting geofence detection:', error)
              Sentry.captureException(error)
            })
          }
        })
        .catch((error) => {
          console.error('Error getting state:', error)
          Sentry.captureException(error)
        })
    }
  }, [ready, permissionsGranted])

  // Replaces old geofences with new ones
  useEffect(() => {
    const fetch = async () => {
      try {
        await BackgroundGeolocation.removeGeofences()
        if (geofences !== undefined && geofences.length > 0) {
          const geofencesMap: Geofence[] = geofences.map((geofence) => ({
            identifier: geofence.identifier,
            latitude: geofence.latitude,
            longitude: geofence.longitude,
            radius: geofence.radius,
            notifyOnEntry: true,
            notifyOnDwell: true,
            notifyOnExit: true,
            extras: {
              main: geofence.main
            }
          }))
          // console.log(geofencesMap)
          await BackgroundGeolocation.addGeofences(geofencesMap)
        }
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
      }
    }

    void fetch()
  }, [geofences])

  // Sets extras
  useEffect(() => {
    const config: {
      extras: GeopositionRestLocationExtras
    } = {
      extras: {}
    }

    if (user !== undefined) {
      config.extras.user = {
        _id: user._id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        uuid: user.uuid,
        profileImage: user.profileImage
      }

      if (currentUserType !== undefined) {
        config.extras.user.userTypeSlug = currentUserType.slug
      }

      if (expoPushToken !== undefined) {
        config.extras.expoPushToken = expoPushToken
      }
    }

    BackgroundGeolocation.setConfig(config as any)
      .then(({ extras }) => {
        // console.log('New extras:', extras)
      })
      .catch((error) => {
        console.error('Error adding device ID to config:', error)
        Sentry.captureException(error)
      })
  }, [user, currentUserType, expoPushToken])
}

export default useGeoposition
