import { client } from '@/common/config'

export const getCheckinQuestions = async () => {
  const { data, error } = await client.GET(
    '/api/app/visit-requests/check-in-questions'
  )

  if (error !== undefined) {
    console.error('Error getting checkin questions:', error)
    return await Promise.reject(error)
  }

  return data.data
}
