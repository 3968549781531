import { client } from '@/common/config'

interface Params {
  safetyInductionUUID: string
}

export const generateInductionReport = async ({
  safetyInductionUUID: uuid
}: Params) => {
  const { data, error } = await client.POST(
    '/api/app/safety-induction/reports/{uuid}/send',
    {
      params: {
        path: {
          uuid
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error generating induction report:', error)
    return await Promise.reject(error)
  }

  return data.data
}
